import React, { SelectHTMLAttributes } from 'react';

import './styles.css';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label: string;
  hasSelectOptionValue: boolean;
  options: Array<{
    value: string;
    label: string;
  }>;
  hasAddButton?: boolean;
  addButtonClick?: (e: any) => Promise<void>;
  addButtonText?: string;
}

const Select: React.FC<SelectProps> = ({
  hasSelectOptionValue,
  label,
  name,
  options,
  hasAddButton,
  addButtonClick,
  addButtonText,
  ...rest
}) => {
  return (
    <div id={rest.id} className="select-block">
      <div className="select-label-container">
        <label htmlFor={name}>{label}</label>
        {hasAddButton && (
          <button type="button" onClick={addButtonClick}>
            {addButtonText || 'adicionar novo registro!'}
          </button>
        )}
      </div>
      <select value={0} id={name} {...rest}>
        {hasSelectOptionValue && <option value={0}>Selecione</option>}

        {options?.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Select;
