import React from 'react';
import Routes from './routes';

import './assets/styles/global.css';
import AppProvider from './providers/AppProvider';
import AuthProvider from './providers/AuthProvider';

export default function App() {
  return (
    <div className="App">
      <AppProvider>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </AppProvider>
    </div>
  );
}
