import React, { FormEvent, useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import Modal from 'react-modal';

import { BiArrowBack, BiBookAdd } from 'react-icons/bi';
import { FiLogIn, FiLogOut } from 'react-icons/fi';

import './styles.css';
import { AuthContext } from '../../providers/AuthProvider';
import { AppContext } from '../../providers/AppProvider';
import Input from '../Input';

interface PageHeaderProps {
  back?: boolean;
  create?: boolean;
  title: string;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const PageHeader: React.FC<PageHeaderProps> = (props) => {
  const { isAuthenticated, authenticate, login, logout } = useContext(
    AuthContext
  );
  const { removeStorageData, getUser } = useContext(AppContext);
  const [modalIsOpen, setIsOpen] = useState(false);

  const [id, setId] = useState('');
  const [password, setPassword] = useState('');

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  async function handleLogin(e: FormEvent) {
    e?.preventDefault();

    if (await authenticate(id, password)) {
      login();
      closeModal();
    } else {
      alert(`Falha ao efetuar o login`);
    }
  }

  function handleLogout() {
    removeStorageData()
      .then(() => logout())
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <header className="page-header">
      <div className="top-bar-container">
        {isAuthenticated && props.create ? (
          <Link to="create">
            <BiBookAdd />
          </Link>
        ) : props.back ? (
          <Link to="/">
            <BiArrowBack />
          </Link>
        ) : (
          <div></div>
        )}

        {isAuthenticated ? (
          <div className="inline">
            <span>{getUser()?.name}</span>
            <Link to="/" onClick={handleLogout}>
              <FiLogOut />
            </Link>
          </div>
        ) : (
          <Link to="/" onClick={openModal}>
            <FiLogIn />
          </Link>
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <form onSubmit={handleLogin}>
          <fieldset>
            <legend>Login</legend>

            <Input
              name="id"
              label="Usuário"
              value={id}
              onChange={(e) => {
                setId(e.target.value);
              }}
            />

            <Input
              name="password"
              label="Senha"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </fieldset>

          <footer>
            <button type="submit">Acessar</button>
          </footer>
        </form>
      </Modal>

      <div className="header-content">
        <strong>{props.title}</strong>

        {props.children}
      </div>
    </header>
  );
};

export default PageHeader;
