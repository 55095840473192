/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, FormEvent, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import PageHeader from '../../components/PageHeader';
import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import Select from '../../components/Select';

import warningIcon from '../../assets/images/icons/warning.svg';

import api from '../../services/api';

import './styles.css';
import { Post } from '../../components/PostItem';
import ModalPage from '../../components/Modal';
import { AppContext } from '../../providers/AppProvider';

function PostUpdate({ match }: any) {
  const history = useHistory();

  const { getUser } = useContext(AppContext);

  const [showModuleModal, setShowModuleModal] = useState<boolean>(false);
  const [showSubmoduleModal, setShowSubmoduleModal] = useState<boolean>(false);
  const [showTypeModal, setShowTypeModal] = useState<boolean>(false);

  const [newModuleDescription, setNewModuleDescription] = useState('');
  const [newSubmoduleDescription, setNewSubmoduleDescription] = useState('');
  const [newTypeDescription, setNewTypeDescription] = useState('');

  const [types, setTypes] = useState<any[]>([]);
  const [modules, setModules] = useState<any[]>([]);
  const [submodules, setSubmodules] = useState<any[]>([]);

  const [id, setId] = useState(0);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [typeId, setTypeId] = useState(0);
  const [moduleId, setModuleId] = useState(0);
  const [submoduleId, setSubmoduleId] = useState(0);
  const [userId, setUserId] = useState('');
  const [timestamp, setTimestamp] = useState(0);
  const [visible, setVisible] = useState(1);
  const [active, setActive] = useState(1);
  const [reviewed, setReviewed] = useState(1);

  useEffect(() => {
    if (match.params.id) {
      getModules();
      getTypes();

      getPost(Number(match.params.id));
    }
  }, [match.params.id]);

  function getModules() {
    api
      .get('getModules.php')
      .then((response) => {
        setModules(response?.data || []);
      })
      .catch(() => {
        setModules([]);
      });
  }

  function getSubmodules(moduleId: number) {
    api
      .get('getSubmodules.php', {
        params: { moduleId },
      })
      .then((response) => {
        setSubmodules(response?.data || []);
      })
      .catch(() => {
        setSubmodules([]);
      });
  }

  function getTypes() {
    api
      .get('getTypes.php')
      .then((response) => {
        setTypes(response?.data || []);
      })
      .catch(() => {
        setTypes([]);
      });
  }

  function getPost(id: number) {
    api
      .get(`getPost.php`, {
        params: { id },
      })
      .then((response) => {
        const post: Post = response.data;

        setId(id);
        setUserId(getUser().id);
        setTimestamp(Date.now());

        setTitle(post.title);
        setDescription(post.description);
        setTypeId(Number(post.typeId));
        setModuleId(Number(post.moduleId));
        setSubmoduleId(Number(post.submoduleId));
        setVisible(Number(post.visible));
        setActive(Number(post.active));
        setReviewed(Number(post.reviewed));

        getSubmodules(Number(post.moduleId));
      });
  }

  function getMaxId(data: any[]) {
    return Math.max(...data.map((item: any) => item.id)) || 0;
  }

  function handleCreateModule(e: FormEvent) {
    e.preventDefault();

    api
      .post('createModule.php', {
        description: newModuleDescription,
      })
      .then((response) => {
        console.log(response?.data);
        setModules(
          response?.data?.map((module: any) => ({
            id: Number(module.id),
            description: module.description,
          })) || []
        );

        setShowModuleModal(false);
        setNewModuleDescription('');
        setModuleId(getMaxId(response?.data));
      })
      .catch(() => {
        alert('Erro no cadastro!');
        setShowModuleModal(false);
        setNewModuleDescription('');
      });
  }

  function handleCreateSubmodule(e: FormEvent) {
    e.preventDefault();

    api
      .post('createSubmodule.php', {
        moduleId,
        description: newSubmoduleDescription,
      })
      .then((response) => {
        setSubmodules(
          response?.data?.map((submodule: any) => ({
            id: Number(submodule.id),
            moduleId: Number(submodule.moduleId),
            description: submodule.description,
          })) || []
        );
        setShowSubmoduleModal(false);
        setNewSubmoduleDescription('');
        setSubmoduleId(getMaxId(response?.data));
      })
      .catch(() => {
        alert('Erro no cadastro!');
        setShowSubmoduleModal(false);
        setNewSubmoduleDescription('');
      });
  }

  function handleCreateType(e: FormEvent) {
    e.preventDefault();

    api
      .post('createType.php', {
        description: newTypeDescription,
      })
      .then((response) => {
        setTypes(
          response?.data?.map((type: any) => ({
            id: Number(type.id),
            description: type.description,
          })) || []
        );
        setShowTypeModal(false);
        setNewTypeDescription('');
        setTypeId(getMaxId(response?.data));
      })
      .catch(() => {
        alert('Erro no cadastro!');
        setShowTypeModal(false);
        setNewTypeDescription('');
      });
  }

  function handleUpdatePost(e: FormEvent) {
    e.preventDefault();

    api
      .post('updatePost.php', {
        id,
        title,
        description: description.replaceAll(
          'data-jodit_iframe_wrapper="1"',
          ''
        ),
        typeId,
        moduleId,
        submoduleId,
        userId,
        timestamp,
        visible,
        active,
        reviewed,
      })
      .then(() => {
        alert('Alteração realizada com sucesso!');

        history.push('/');
      })
      .catch(() => {
        alert('Erro no cadastro!');
      });
  }

  return (
    <div id="page-post-form" className="container">
      <PageHeader title="Deixe a documentação cada vez melhor." back={true} />

      <main>
        <div>
          <fieldset>
            <legend>Dados da documentação</legend>

            <Input
              name="title"
              label="Título"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />

            <Textarea
              name="description"
              label="Descrição"
              value={description}
              onBlur={(e: any) => {
                setDescription(String(e.target.innerHTML));
              }}
            />

            <Select
              name="moduleId"
              label="Módulo"
              hasSelectOptionValue={true}
              value={moduleId}
              onChange={(e) => {
                setModuleId(Number(e.target.value));
                setSubmoduleId(0);
                getSubmodules(Number(e.target.value));
              }}
              options={modules?.map((item) => ({
                value: item.id,
                label: item.description,
              }))}
              hasAddButton={true}
              addButtonClick={async (e) => {
                setShowModuleModal(true);
              }}
              addButtonText="adicionar um novo módulo?"
            />

            <ModalPage isOpen={showModuleModal}>
              <form onSubmit={handleCreateModule}>
                <fieldset>
                  <legend>Adicionar novo módulo</legend>

                  <Input
                    name="moduleDescription"
                    label="Descrição"
                    value={newModuleDescription}
                    onChange={(e) => {
                      setNewModuleDescription(e.target.value);
                    }}
                  />
                </fieldset>

                <footer>
                  <button type="submit">Salvar</button>
                </footer>
              </form>
            </ModalPage>

            <Select
              name="submoduleId"
              label="Submódulo"
              hasSelectOptionValue={true}
              value={submoduleId}
              onChange={(e) => {
                setSubmoduleId(Number(e.target.value));
              }}
              options={submodules?.map((item) => ({
                value: item.id,
                label: item.description,
              }))}
              hasAddButton={true}
              addButtonClick={async (e) => {
                setShowSubmoduleModal(true);
              }}
              addButtonText="adicionar um novo submódulo?"
            />

            <ModalPage isOpen={showSubmoduleModal}>
              <form onSubmit={handleCreateSubmodule}>
                <fieldset>
                  <legend>Adicionar novo submódulo</legend>

                  <Input
                    name="subModuleDescription"
                    label="Descrição"
                    value={newSubmoduleDescription}
                    onChange={(e) => {
                      setNewSubmoduleDescription(e.target.value);
                    }}
                  />
                </fieldset>

                <footer>
                  <button type="submit">Salvar</button>
                </footer>
              </form>
            </ModalPage>

            <Select
              name="typeId"
              label="Tipo"
              hasSelectOptionValue={true}
              value={typeId}
              onChange={(e) => {
                setTypeId(Number(e.target.value));
              }}
              options={types?.map((item) => ({
                value: item.id,
                label: item.description,
              }))}
              hasAddButton={true}
              addButtonClick={async (e) => {
                setShowTypeModal(true);
              }}
              addButtonText="adicionar um novo tipo?"
            />

            <ModalPage isOpen={showTypeModal}>
              <form onSubmit={handleCreateType}>
                <fieldset>
                  <legend>Adicionar novo tipo</legend>

                  <Input
                    name="typeDescription"
                    label="Descrição"
                    value={newTypeDescription}
                    onChange={(e) => {
                      setNewTypeDescription(e.target.value);
                    }}
                  />
                </fieldset>

                <footer>
                  <button type="submit">Salvar</button>
                </footer>
              </form>
            </ModalPage>

            <Select
              name="active"
              label="Ativo?"
              hasSelectOptionValue={false}
              value={active}
              onChange={(e) => {
                setActive(Number(e.target.value));
              }}
              options={[
                { value: '1', label: 'Sim' },
                { value: '0', label: 'Não' },
              ]}
            />

            <Select
              name="reviewed"
              label="Revisado?"
              hasSelectOptionValue={false}
              value={reviewed}
              onChange={(e) => {
                setReviewed(Number(e.target.value));
              }}
              options={[
                { value: '1', label: 'Sim' },
                { value: '0', label: 'Não' },
              ]}
            />

            <Select
              name="visible"
              label="Visível para o público?"
              hasSelectOptionValue={false}
              value={visible}
              onChange={(e) => {
                setVisible(Number(e.target.value));
              }}
              options={[
                { value: '1', label: 'Sim' },
                { value: '0', label: 'Não' },
              ]}
            />
          </fieldset>

          <footer>
            <p>
              <img src={warningIcon} alt="Aviso importante" />
              Importante! <br />
              Preencha todos os dados
            </p>
            <button onClick={handleUpdatePost}>Salvar alterações</button>
          </footer>
        </div>
      </main>
    </div>
  );
}

export default PostUpdate;
