import React, { useEffect, useState } from 'react';

import Modal from 'react-modal';

import './styles.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

interface ModalProps {
  isOpen: boolean;
}

const ModalPage: React.FC<ModalProps> = (props) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      {props.children}
    </Modal>
  );
};

export default ModalPage;
