import React, { useContext, useState } from 'react';

import { format } from 'date-fns';

import './styles.css';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../providers/AuthProvider';

export interface Post {
  id: number;
  title: string;
  description: string;
  moduleId: number;
  moduleDescription?: string;
  submoduleId: number;
  submoduleDescription?: string;
  typeId: number;
  typeDescription?: string;
  userId: string;
  userName?: string;
  timestamp: number;
  visible: number;
  active: number;
  reviewed: number;
}

interface PostItemProps {
  post: Post;
}

const PostItem: React.FC<PostItemProps> = ({ post }) => {
  const { isAuthenticated } = useContext(AuthContext);

  const [expandedPostId, setExpandedPostId] = useState(0);

  function createMarkup() {
    return {
      __html: post.description,
    };
  }

  function formatDateTime(timestamp: number) {
    return format(new Date(Number(timestamp)), 'dd/MM/yyyy HH:mm:ss');
  }

  return (
    <article className="post-item">
      <header>
        <h1>{post.title}</h1>
        <div>
          <strong>{post.userName}</strong>
          <span>{formatDateTime(post.timestamp)}</span>
        </div>
      </header>

      <div
        dangerouslySetInnerHTML={createMarkup()}
        className={post.id === expandedPostId ? 'expand' : undefined}
      ></div>

      <button
        onClick={() => {
          expandedPostId ? setExpandedPostId(0) : setExpandedPostId(post.id);
        }}
      >
        Ver {expandedPostId ? 'menos' : 'mais'}
      </button>

      {isAuthenticated && <Link to={`${post.id}/update`}>Alterar</Link>}

      <footer>
        <span className="module capitalize">
          {post.moduleDescription?.toLocaleLowerCase()}
          {post.submoduleId
            ? ` / ${post.submoduleDescription?.toLocaleLowerCase()}`
            : ''}
        </span>

        {isAuthenticated && !post.reviewed && (
          <span className="not-reviewed capitalize">não revisado</span>
        )}
        {isAuthenticated && !post.visible && (
          <span className="private capitalize">restrito</span>
        )}
        {isAuthenticated && !post.active && (
          <span className="inactive capitalize">inativo</span>
        )}
        <span className="type capitalize">
          {post.typeDescription?.toLocaleLowerCase()}
        </span>
      </footer>
    </article>
  );
};

export default PostItem;
