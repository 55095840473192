/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';

import PostList from './pages/PostList';
import PostCreate from './pages/PostCreate';
import PostUpdate from './pages/PostUpdate';
import { AuthContext } from './providers/AuthProvider';
import { AppContext } from './providers/AppProvider';
import PrivateRoute from './components/PrivateRoute';

export default function Routes() {
  const { login, logout } = useContext(AuthContext);

  const { getStorageData } = useContext(AppContext);

  useEffect(() => {
    getStorageData()
      .then((app: any) => {
        if (app) {
          login();
        } else {
          logout();
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);

  return (
    <HashRouter>
      <Switch>
        <Route path="/" exact component={PostList} />
        <PrivateRoute path="/create" exact component={PostCreate} />
        <PrivateRoute path="/:id/update" component={PostUpdate} />
        <Redirect to="/" />
      </Switch>
    </HashRouter>
  );
}
