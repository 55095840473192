import React, { useState, createContext, useContext } from 'react';
import api from '../services/api';
import { AppContext } from './AppProvider';

export interface IAuthContext {
  isAuthenticated: boolean;
  login(): void;
  logout(): void;
  authenticate(id: string, password: string): Promise<boolean>;
}

interface AuthProviderProps {
  children: any;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { setStorageData } = useContext(AppContext);

  const login = () => {
    setIsAuthenticated(true);
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  async function authenticate(id: string, password: string) {
    const user = await api
      .post('login.php', {
        id,
        password,
      })
      .then((response) => response.data)
      .catch(() => null);

      console.log(user);

    if (user) {
      setStorageData({
        user,
      });

      return true;
    }

    return false;
  }

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, authenticate }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
