/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, FormEvent, useContext } from 'react';

import PageHeader from '../../components/PageHeader';
import PostItem, { Post } from '../../components/PostItem';
import Input from '../../components/Input';
import Select from '../../components/Select';
import FlatList from 'flatlist-react';

import { AuthContext } from '../../providers/AuthProvider';
import { AppContext } from '../../providers/AppProvider';

import api from '../../services/api';

import './styles.css';

const PostList: React.FC<any> = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const { getQueryData, setQueryData } = useContext(AppContext);

  const [posts, setPosts] = useState<any[]>([]);
  const [types, setTypes] = useState<any[]>([]);
  const [modules, setModules] = useState<any[]>([]);
  const [submodules, setSubmodules] = useState<any[]>([]);

  const [typeId, setTypeId] = useState<number>(0);
  const [moduleId, setModuleId] = useState<number>(0);
  const [submoduleId, setSubmoduleId] = useState<number>(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setModuleId(getQueryData().moduleId);
    setSubmoduleId(getQueryData().submoduleId);
    setTypeId(getQueryData().typeId);
    setSearch(getQueryData().search);

    getModules();
    getTypes();
    getSubmodules(getQueryData().moduleId);

    getPosts();
  }, []);

  function getModules() {
    api
      .get('getModules.php')
      .then((response) => {
        setModules(response?.data || []);
      })
      .catch(() => {
        setModules([]);
      });
  }

  function getSubmodules(moduleId: number) {
    api
      .get('getSubmodules.php', {
        params: { moduleId },
      })
      .then((response) => {
        setSubmodules(response?.data || []);
      })
      .catch(() => {
        setSubmodules([]);
      });
  }

  function getTypes() {
    api
      .get('getTypes.php')
      .then((response) => {
        setTypes(response?.data || []);
      })
      .catch(() => {
        setTypes([]);
      });
  }

  useEffect(() => {
    setPosts([]);
  }, [isAuthenticated]);

  function getPosts() {
    setQueryData({
      moduleId,
      submoduleId,
      typeId,
      search,
    });

    api
      .get('getPosts.php', {
        params: { isAuthenticated, typeId, moduleId, submoduleId, search },
      })
      .then((response) => {
        setPosts(
          response?.data?.map((post: Post) => ({
            id: Number(post.id),
            title: post.title,
            description: post.description,
            typeId: Number(post.typeId),
            typeDescription: post.typeDescription,
            moduleId: Number(post.moduleId),
            moduleDescription: post.moduleDescription,
            submoduleId: Number(post.submoduleId),
            submoduleDescription: post.submoduleDescription,
            userId: post.userId,
            userName: post.userName,
            timestamp: Number(post.timestamp),
            visible: Number(post.visible),
            active: Number(post.active),
            reviewed: Number(post.reviewed),
          })) || []
        );
      })
      .catch(() => {
        setPosts([]);
      });
  }

  async function searchPosts(e: FormEvent) {
    e.preventDefault();

    getPosts();
  }

  return (
    <div id="page-post-list" className="container">
      <PageHeader title="Estas são as documentações encontradas." create={true}>
        <form id="search-posts" onSubmit={searchPosts}>
          <Select
            id="moduleSelect"
            name="moduleId"
            label="Módulo"
            hasSelectOptionValue={true}
            value={moduleId}
            onChange={(e) => {
              setModuleId(Number(e.target.value));
              setSubmoduleId(0);
              getSubmodules(Number(e.target.value));
            }}
            options={modules?.map((item) => ({
              value: item.id,
              label: item.description,
            }))}
          />
          <Select
            id="submoduleSelect"
            name="submoduleId"
            label="Submódulo"
            hasSelectOptionValue={true}
            value={submoduleId}
            onChange={(e) => {
              setSubmoduleId(Number(e.target.value));
            }}
            options={submodules?.map((item) => ({
              value: item.id,
              label: item.description,
            }))}
          />

          <Select
            id="typeSelect"
            name="typeId"
            label="Tipo"
            hasSelectOptionValue={true}
            value={typeId}
            onChange={(e) => {
              setTypeId(Number(e.target.value));
            }}
            options={types?.map((item) => ({
              value: item.id,
              label: item.description,
            }))}
          />

          <Input
            id="searchInput"
            type="text"
            name="search"
            label="Busca"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />

          <button id="searchButton" type="submit">
            Buscar
          </button>
        </form>
      </PageHeader>

      <main>
        <FlatList
          list={posts}
          renderItem={(post: Post) => <PostItem key={post.id} post={post} />}
          renderWhenEmpty={() => <h2>Nenhum registro encontrado.</h2>}
        />
      </main>
    </div>
  );
};

export default PostList;
