import React from 'react';
import JoditEditor, { JoditProps } from 'jodit-react';

import './styles.css';

interface TextareaProps extends JoditProps {
  name: string;
  label: string;
}

const config: any = {
  placeholder: 'Começe a escrever...',
  height: 500,
};

const Textarea: React.FC<TextareaProps> = ({ label, name, ...rest }) => {
  return (
    <div className="textarea-block">
      <label htmlFor={name}>{label}</label>
      <JoditEditor config={config} {...rest} />
    </div>
  );
};

export default Textarea;
