import React, { createContext, useState } from 'react';

export interface Query {
  moduleId: number;
  submoduleId: number;
  typeId: number;
  search: string;
}

export interface IAppContext {
  getUser(): any;
  getQueryData(): Query;
  setQueryData(query: Query): void;
  getStorageData(): Promise<any>;
  removeStorageData(): Promise<void>;
  setStorageData(app: any): Promise<void>;
}

interface AppProviderProps {
  children: any;
}

export const AppContext = createContext<IAppContext>({} as IAppContext);

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [query, setQuery] = useState<Query>({
    moduleId: 0,
    submoduleId: 0,
    typeId: 0,
    search: '',
  });

  async function setStorageData(app: any) {
    localStorage.setItem('@manual:app', JSON.stringify(app));
    setUser(app.user);
  }

  async function getStorageData() {
    const data = localStorage.getItem('@manual:app');

    if (!data) {
      return null;
    }

    const app = JSON.parse(data);
    setUser(app.user);

    return app;
  }

  async function removeStorageData() {
    localStorage.removeItem('@manual:app');
    setUser(null);
  }

  function getQueryData() {
    return query;
  }

  function setQueryData(data: any) {
    setQuery(data);
  }

  function getUser() {
    return user;
  }

  return (
    <AppContext.Provider
      value={{
        getUser,
        getQueryData,
        setQueryData,
        removeStorageData,
        setStorageData,
        getStorageData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
